import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";
import { useAppState } from "@state";
import gsap from "gsap";
import { Icon } from "@atoms";

const Modal = () => {
  const modalRef = useRef();
  const modalContent = useRef();
  const tl = useRef();
  const [{ modal: _modal }, dispatch] = useAppState();
  const { content: Content, modal, background } = _modal;
  useEffect(() => {
    if (modalRef) {
      tl.current = gsap
        .timeline()
        .call(() => {
          ReactDOM.unmountComponentAtNode(modalContent.current);
        })
        .call(() => {
          ReactDOM.render(Content, modalContent.current);
        })
        .set(modalRef.current, { display: "none", pointerEvents: "none" })
        .set(modalRef.current, { display: "flex", pointerEvents: "auto" })
        .to(modalRef.current, { opacity: 1, duration: 0.2 });
    }
  }, [Content]);
  useEffect(() => {
    if (modal) {
      tl.current.play();
    } else {
      tl.current.reverse();
    }
  }, [modal]);
  return (
    <div
      ref={modalRef}
      className="z-50 fixed inset-0 opacity-0 pointer-events-none hidden flex-col items-center justify-center"
    >
      <button
        type="button"
        className={clsx(
          "absolute inset-0 flex items-center text-transparent uppercase font-bold text-xs uppercase right-0 z-0 p-6",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
      >
        <span style={{ display: "none" }}>close</span>
      </button>
      <button
        type="button"
        className={clsx(
          "group absolute top-0 group flex items-center uppercase font-bold text-xs uppercase right-0 z-30 p-6 cursor-[zoom-out]",
          {}
        )}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
      >
        {/* <span>Close</span> */}
        <Icon
          name="close"
          className={clsx(
            "w-8 h-8 text-white transform transition duration-200 opacity-70",
            {}
          )}
        />
      </button>
      <div
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        className={clsx(
          "opacity-70 absolute inset-0 z-0 cursor-[zoom-out]",
          background
        )}
      />
      <div
        ref={modalContent}
        onClick={() => {
          dispatch({ type: "closeModal" });
        }}
        className={clsx(
          "w-full h-full flex justify-center items-center z-20 cursor-[zoom-out]",
          {}
        )}
      />
    </div>
  );
};

export default Modal;
