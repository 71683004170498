import React from "react";
import clsx from "clsx";
import Text from "./Text";
import Icon from "./Icon";

const Field = ({
  label,
  name,
  required,
  placeholder,
  type,
  textarea,
  hideLabel,
  onChange,
  cols = 1,
  light,
  singleLine,
  options,
  className: _className,
}) => {
  return (
    <div
      className={clsx(
        "relative",
        { "col-span-full md:col-span-1": cols === 1 },
        { "col-span-full": cols === 2 },
        { "flex items-center": singleLine },
        _className
      )}
    >
      <label
        htmlFor={name}
        className={clsx(
          "inline-flex",
          { "mb-3": type || textarea },
          { "sr-only": hideLabel },
          { "mx-4 cursor-pointer relative": type === "checkbox" },
          { "w-max pr-4 !mb-0": singleLine }
        )}
      >
        <Text
          variant="sm"
          className={clsx(
            "italic",
            { "text-gold": !light },
            { "text-white": light }
          )}
        >
          {required ? `${label}*` : label}
        </Text>
      </label>
      {!textarea && type === "select" && (
        <select
          id={`${name}`}
          name={name}
          className="font-serif p-2 border border-gray bg-white rounded-sm w-full w-full italic font-serif text-[#999]"
          onChange={onChange}
        >
          {options?.map(option => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      )}
      {!textarea && type !== "checkbox" && type !== "select" && (
        <input
          type={type}
          name={name}
          id={name}
          required={required}
          className={clsx(
            "font-serif p-2 border border-gray rounded-sm w-full w-full placeholder-shown:italic placeholder-shown:font-serif",
            { "bg-light-gray": !light },
            { "bg-white": light }
          )}
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
      {!textarea && type === "checkbox" && (
        <div className="relative order-first self-center">
          <input
            type={type}
            name={name}
            id={name}
            required={required}
            className="text-white p-2 appearance-none w-6 h-6 checked:bg-white mr-4 border-2 rounded-sm rounded-none cursor-pointer"
            placeholder={placeholder}
            onChange={onChange}
          />
          <Icon
            name="check"
            className="text-black w-4 h-4 absolute z-0 inset-1 pointer-events-none"
          />
        </div>
      )}
      {textarea && (
        <textarea
          name={name}
          id={name}
          required={required}
          className="font-serif !resize-y p-2 border border-gray rounded-sm w-full text-sm min-h-28 placeholder-shown:italic placeholder-shown:font-serif"
          placeholder={placeholder}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default Field;
