/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable import/prefer-default-export */

import React from "react";
import { AppStateProvider } from "@state";
import "./src/css/index.css";

export const wrapPageElement = ({ element }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};

export const wrapRootElement = ({ element }) => {
  return <AppStateProvider>{element}</AppStateProvider>;
};
