import React from "react";
import clsx from "clsx";
import { Container, Text } from "@atoms";

const Timeline = ({ headline, timeline }) => {
  if (timeline?.length) {
    return (
      <section className="bg-blue-light py-12 md:py-16">
        <Container variant="lg" className="">
          <Text variant="h4" className="text-center text-blue">
            {headline}
          </Text>

          <div className="mt-16 max-w-xl mx-auto">
            {!!timeline?.length && (
              <ul className="">
                {timeline?.map((node, i) => {
                  return (
                    <li
                      className="grid grid-cols-1 md:grid-cols-2 gap-5 pb-8 last:pb-0 relative"
                      key={i}
                    >
                      <div className="absolute w-5 h-full mx-auto md:inset-0">
                        <div className="bg-white border-[.2rem] border-blue rounded-xl w-5 h-5 z-10 relative" />
                        <div className="bg-blue w-[.2em] h-full absolute inset-0 mx-auto z-0" />
                      </div>
                      <div
                        className={clsx(
                          "",
                          {
                            "pl-8 md:pl-0 pr-0 md:pr-4 text-left md:text-right col-start-1":
                              (i + 1) % 2 === 0,
                          },
                          {
                            "pl-8 md:pl-4 order-last col-start-1 md:col-start-2":
                              (i + 1) % 2 !== 0,
                          }
                        )}
                      >
                        <Text
                          variant="h6"
                          className="tracking-tight text-blue mb-2"
                        >
                          {node?.eventYear}
                        </Text>
                        <Text variant="sm">{node?.eventDescription}</Text>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Container>
      </section>
    );
  }
  return null;
};

export default Timeline;
