exports.components = {
  "component---src-craft-dynamic-queries-careers-index-query-js": () => import("./../../../src/craft/dynamicQueries/CareersIndexQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-careers-index-query-js" */),
  "component---src-craft-dynamic-queries-careers-query-js": () => import("./../../../src/craft/dynamicQueries/CareersQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-careers-query-js" */),
  "component---src-craft-dynamic-queries-contact-query-js": () => import("./../../../src/craft/dynamicQueries/ContactQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-contact-query-js" */),
  "component---src-craft-dynamic-queries-food-safety-query-js": () => import("./../../../src/craft/dynamicQueries/FoodSafetyQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-food-safety-query-js" */),
  "component---src-craft-dynamic-queries-foods-query-js": () => import("./../../../src/craft/dynamicQueries/FoodsQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-foods-query-js" */),
  "component---src-craft-dynamic-queries-homepage-query-js": () => import("./../../../src/craft/dynamicQueries/HomepageQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-homepage-query-js" */),
  "component---src-craft-dynamic-queries-innovation-query-js": () => import("./../../../src/craft/dynamicQueries/InnovationQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-innovation-query-js" */),
  "component---src-craft-dynamic-queries-markets-query-js": () => import("./../../../src/craft/dynamicQueries/MarketsQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-markets-query-js" */),
  "component---src-craft-dynamic-queries-our-company-query-js": () => import("./../../../src/craft/dynamicQueries/OurCompanyQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-our-company-query-js" */),
  "component---src-craft-dynamic-queries-our-markets-index-query-js": () => import("./../../../src/craft/dynamicQueries/OurMarketsIndexQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-our-markets-index-query-js" */),
  "component---src-craft-dynamic-queries-simple-pages-query-js": () => import("./../../../src/craft/dynamicQueries/SimplePagesQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-simple-pages-query-js" */),
  "component---src-craft-dynamic-queries-sustainability-query-js": () => import("./../../../src/craft/dynamicQueries/SustainabilityQuery.js" /* webpackChunkName: "component---src-craft-dynamic-queries-sustainability-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

