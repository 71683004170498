import React from "react";
import { Container, Text } from "@atoms";
import { AppLink } from "@base";
import { useFooterData } from "@staticQueries";
import { logoWhite } from "@images";

const Footer = () => {
  const { links, social, copyright, supply } = useFooterData();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="py-10">
      <Container variant="lg">
        <div className="flex flex flex-col md:flex-row">
          <div className="w-24 md:w-36 pr-0 md:pr-10 mx-auto md:mr-10 md:ml-0 mb-6">
            <img src={logoWhite} />
          </div>
          <div className="w-full md:w-[70%]">
            <nav
              aria-label="footer-nav"
              className="flex flex-col lg:flex-row justify-between w-full"
            >
              <div className="flex flex-row justify-between flex-wrap w-full">
                {links?.map((col, i) => {
                  return (
                    <ul className="w-1/3 md:w-auto first:pl-0 pl-1">
                      {col?.map((link, i) => {
                        if (link?.small) {
                          return (
                            <li
                              className="mb-1 w-full"
                              key={`footer-nav--${i}`}
                            >
                              <Text
                                variant="xs"
                                className="font-bold relative text-blue-light-text leading-loose max-w-[8rem] !font-sans"
                              >
                                {link?.text}
                              </Text>
                            </li>
                          );
                        }
                        return (
                          <li className="mb-1" key={`footer-nav--${i}`}>
                            <AppLink
                              to={link?.url}
                              activeClassName="underline underline-offset-[0.325em] decoration-2 decoration-gold hover:opacity-70 duration-115"
                              className="decoration-2 underline underline-offset-[0.325em] decoration-transparent text-white hover:opacity-70 duration-115"
                            >
                              <Text
                                variant="lg"
                                className="font-bold relative !font-sans"
                              >
                                {link?.text}
                              </Text>
                            </AppLink>
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </div>
              <ul className="">
                {social?.map((s, i) => {
                  return (
                    <li
                      className="mb-1"
                      key={`footer-nav--${i + links?.length}`}
                    >
                      <AppLink
                        to={s?.url}
                        className="text-white hover:opacity-70 duration-115"
                      >
                        <Text variant="sm" className="relative !font-sans">
                          {s?.text}
                        </Text>
                      </AppLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div className="flex flex-row mt-3 justify-center md:justify-start">
              <Text
                variant="xs"
                className="!inline text-blue-light-text !font-sans"
              >
                <span>
                  &copy; {currentYear} {copyright}
                </span>
              </Text>

              {supply?.url && (
                <AppLink
                  to={supply?.url}
                  className="ml-1 text-white hover:opacity-70 transition-opacity duration-115"
                >
                  <Text variant="xs" className="!font-sans">
                    {supply?.text}
                  </Text>
                </AppLink>
              )}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
