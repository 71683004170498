import React, { useRef, useState } from "react";
import { Field, Button, Text } from "@atoms";

const NewsletterForm = ({ successState }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = successState || useState(false);
  const [form, setForm] = useState({});
  const errorMessage = useRef();

  const onSubmit = e => {
    e.preventDefault();
    if (form && !success) {
      const formData = new FormData(e.target);
      fetch(`/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => setSuccess(true))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <>
      <div ref={errorMessage} className="h-0 overflow-hidden">
        <Text variant="xs" className="inline-block mb-2 py-3 text-red">
          {error}
        </Text>
      </div>
      <form
        name="Contact Form"
        onSubmit={onSubmit}
        data-netlify="true"
        method="post"
        className="grid grid-cols-2 gap-3"
      >
        <Field
          label="First Name"
          placeholder="First Name"
          name="firstName"
          required
          type="text"
          cols={1}
          light
        />
        <Field
          label="Last Name"
          placeholder="Last Name"
          name="lastName"
          required
          type="text"
          cols={1}
          light
        />
        <Field
          label="Email address"
          placeholder="Your email address here"
          name="email"
          required
          type="email"
          cols={2}
          light
        />
        <Field
          label="Zip Code"
          placeholder="Zip Code"
          name="text"
          required
          type="text"
          cols={2}
          light
        />
        <div className="col-span-full text-center">
          <Button type="submit" color="gold">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default NewsletterForm;
