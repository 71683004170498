import React from "react";
import { Text, Container } from "@atoms";
import { useValueData } from "@staticQueries";

const OurValues = ({ headline, columns }) => {
  const { values } = useValueData();

  return (
    <section className="bg-white py-12 md:py-16">
      <Container variant="lg">
        <Text variant="h4" className="text-center text-blue">
          {headline}
        </Text>

        {columns && (
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-8 md:mb-16 gap-8">
            <Text variant="sm">{columns?.left}</Text>
            <Text variant="sm">{columns?.right}</Text>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 gap-y-8 md:gap-y-16">
          {values?.map((value, i) => {
            return (
              <div className="flex flex-col text-center space-y-4 px-4" key={i}>
                <img src={value?.image?.url} className="w-12 mx-auto" />
                <Text variant="h6" className="text-blue">
                  {value?.title}
                </Text>
                <Text variant="sm" className="text-black italic">
                  {value?.description}
                </Text>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default OurValues;
