import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Sticky = ({ children, className }) => {
  // setup refs
  const el = useRef();
  const elContainer = useRef();
  const endTrigger = useRef();

  useEffect(() => {
    let elObserver;
    let scrollTrigger;
    if (el.current) {
      // create scroll listener
      scrollTrigger = ScrollTrigger.create({
        trigger: el.current,
        // stick to top if element is smaller than window, otherwise
        // stick to bottom
        start: () =>
          el.current.clientHeight >= window.innerHeight
            ? "bottom bottom"
            : "top top",
        // set scroll distance euqal to empty space in parent container
        end: () =>
          `+=${elContainer.current.clientHeight - el.current.clientHeight}px`,
        pin: true,
        endTrigger: endTrigger.current,
        invalidateOnRefresh: true,
        pinSpacing: false,
        anticipatePin: 1,
      });
      // create resize observer
      elObserver = new ResizeObserver(entries => {
        entries.forEach(() => {
          scrollTrigger.refresh();
        });
      });
      // observe children
      Array.from(el.current.children).forEach(child => {
        elObserver.observe(child);
      });
    }
    return () => {
      // remove observer
      if (elObserver) {
        elObserver.disconnect();
      }
      // remove scrollTrigger
      if (scrollTrigger) {
        scrollTrigger.kill();
      }
    };
  }, []);

  return (
    // the container
    <div ref={elContainer} className="relative w-full h-full">
      {/* the sticky el itself */}
      <div ref={el} className={className}>
        {children}
      </div>
      {/* end trigger at bottom of container */}
      <div ref={endTrigger} className="absolute bottom-0 left-0 right-0 h-px" />
    </div>
  );
};

export default Sticky;
