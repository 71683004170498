import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          linkList0 {
            ... on Craft_linkList0_link_BlockType {
              linkObject {
                url
                text
              }
            }
          }
          linkList1 {
            ... on Craft_linkList1_link_BlockType {
              linkObject {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { linkList0, linkList1 } = craft.globalSet;

  return {
    left: linkList0?.map(link => link?.linkObject),
    right: linkList1?.map(link => link?.linkObject),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
