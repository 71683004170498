import React, { useRef, useEffect } from "react";
import { Text } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import { useHeaderData } from "@staticQueries";
import gsap from "gsap";

const MobileNav = () => {
  const { left, right } = useHeaderData();
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;
  const mobileNav = useRef();

  useEffect(() => {
    if (mobileNav.current) {
      if (showNav) {
        // setScrollPosition(windowScroll);
        gsap.to(mobileNav.current, {
          height: "auto",
          pointerEvents: "auto",
          duration: 0.6,
          ease: "power3.inOut",
        });
        gsap.to("nav .mobile-links", {
          opacity: 1,
          duration: 0.8,
          ease: "power2.inOut",
        });
      } else {
        gsap.to("nav .mobile-links", {
          opacity: 0,
          duration: 0.4,
          ease: "power2.inOut",
        });
        gsap.to(mobileNav.current, {
          height: "0",
          pointerEvents: "none",
          duration: 0.6,
          ease: "power3.inOut",
        });
      }
    }
  }, [showNav]);

  return (
    <nav
      aria-label="mobile-nav"
      ref={mobileNav}
      className="relative z-10 grid grid-cols-2 bg-blue top-8 overflow-hidden h-0"
    >
      <ul className="flex flex-col py-4">
        {left?.map((nav, i) => {
          return (
            <li className="mobile-links" key={`left-mobile-nav--${i}`}>
              <AppLink
                to={nav?.url}
                activeClassName="header-active"
                className="text-white hover:opacity-70 transition-opacity duration-115 inline-block px-4 py-2"
                onClick={() => {
                  dispatch({
                    type: "hideNav",
                  });
                }}
              >
                <Text variant="lg" className="uppercase font-bold relative">
                  {nav?.text}
                </Text>
              </AppLink>
            </li>
          );
        })}
      </ul>

      <ul className="flex flex-col py-4">
        {right?.map((nav, i) => {
          return (
            <li className="mobile-links" key={`right-mobile-nav--${i}`}>
              <AppLink
                to={nav?.url}
                activeClassName="header-active"
                className="text-white hover:opacity-70 transition-opacity duration-115 inline-block px-4 py-2"
                onClick={() => {
                  dispatch({
                    type: "hideNav",
                  });
                }}
              >
                <Text variant="lg" className="uppercase font-bold relative">
                  {nav?.text}
                </Text>
              </AppLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MobileNav;
