import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query CultureCareersQuery {
    craft {
      globalSet(handle: "cultureCareersFooterSection") {
        ... on Craft_cultureCareersFooterSection_GlobalSet {
          cultureImage(withTransforms: ["card"]) {
            width(transform: "card")
            height(transform: "card")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "card")
          }
          cultureTitle
          cultureDescription
          careersImage(withTransforms: ["card"]) {
            width(transform: "card")
            height(transform: "card")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "card")
          }
          careersTitle
          careersDescription
        }
      }
      entries(section: "careers", limit: 3) {
        ... on Craft_careers_careers_Entry {
          title
          jobLocation
          jobSummary
          url
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const {
    // Culture
    cultureImage,
    cultureTitle,
    cultureDescription,
    // Career
    careersImage,
    careersTitle,
    careersDescription,
  } = craft.globalSet;

  const entries = craft.entries.map(job => {
    return {
      title: job?.title,
      location: job?.jobLocation,
      summary: job?.jobSummary,
      url: job?.url,
    };
  });

  return {
    culture: {
      title: cultureTitle,
      description: cultureDescription,
      image: resolveImage(cultureImage),
    },
    careers: {
      title: careersTitle,
      description: careersDescription,
      image: resolveImage(careersImage),
    },
    jobs: entries,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
