import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview } from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          nestedLinkList {
            ... on Craft_nestedLinkList_links_BlockType {
              list {
                ... on Craft_list_BlockType {
                  linkObject {
                    text
                    url
                  }
                  small
                }
              }
            }
          }
          linkList0 {
            ... on Craft_linkList0_link_BlockType {
              linkObject {
                text
                url
              }
            }
          }
          label0
          link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { nestedLinkList, linkList0, label0, link0 } = craft.globalSet;

  return {
    links: nestedLinkList?.map(col => {
      return col?.list?.map(link => {
        return {
          small: link?.small,
          ...link?.linkObject,
        };
      });
    }),
    social: linkList0?.map(link => link?.linkObject),
    copyright: label0,
    supply: link0,
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
