import React from "react";
import clsx from "clsx";

const Icon = ({
  name,
  className: _className = "w-12 h-12",
  fitHeight = false,
}) => {
  if (name) {
    // eslint-disable-next-line global-require
    const Content = React.memo(require("@svg")[name] || name);
    return (
      <span
        className={clsx(
          "block",
          {
            icon: !fitHeight,
            "icon--fit-height": fitHeight,
          },
          _className
        )}
      >
        <Content />
      </span>
    );
  }
  return null;
};

export default Icon;
