import React from "react";
import clsx from "clsx";
import { Container, Text, Button } from "@atoms";

const BrandStatement = ({ icon, headline }) => {
  return (
    <section
      className={clsx("bg-gold", { "pt-12 pb-14": icon }, { "py-2": !icon })}
    >
      <Container variant="xl" className="text-center">
        <div className="mx-auto max-w-3xl px-4 md:px-8">
          {icon && <img src={icon?.url} className="w-14 mx-auto" />}

          <div className="my-10 space-y-8">
            <Text
              variant="h3"
              className="text-white !font-serif normal-case !font-normal italic leading-normal tracking-normal"
            >
              {headline?.replace(/\n/g, "<br>")}
            </Text>
          </div>

          {icon && <Button to="/company">Read More</Button>}
        </div>
      </Container>
    </section>
  );
};

export default BrandStatement;
