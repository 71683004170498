import React from "react";
import { useDefaultsData } from "@staticQueries";
import { Helmet } from "react-helmet";

const Meta = ({
  title: _title,
  metaTitle: _metaTitle,
  metaDescription,
  metaImage: _metaImage,
  url,
  slug,
}) => {
  if (useDefaultsData) {
    const { meta } = useDefaultsData();
    const { siteTitle, metaImage: defaultMetaImage } = meta;

    if (typeof window === "undefined") {
      let errors = "";
      if (!_title) errors += "title ";
      if (metaDescription) errors += "metaDescription ";
      // if (_metaImage) errors += "_metaImage ";
      if (url) errors += "url";
      if (errors?.length) {
        // eslint-disable-next-line no-console
        console.log("\x1b[31m", `${slug} missing meta: ${errors}`);
      }
    }

    const metaTitle = `Superior Foods | ${_metaTitle || _title || siteTitle}`;
    // const metaImage = metaImage

    const metaImage = _metaImage || defaultMetaImage || false;

    let metaImageUrl = "";

    if (metaImage && metaImage?.url) {
      metaImageUrl = metaImage?.url;
      // const domain = `${process.env.GATSBY_IMGIX_SUBDOMAIN}.imgix.net`;

      // const processedUrl = encodeURI(
      //   metaImage?.url.replace(`${process.env.GATSBY_IMGIX_BASE_URL}`, "/")
      // );

      // const ixConfig = { domain };

      // // setup client if there is a domain in the config
      // let client;
      // const uix = ixConfig.domain;
      // if (uix) {
      //   client = new ImgixClient(ixConfig);
      // }

      // // configure default params
      // let ixParams = {
      //   auto: "compress,format",
      //   fit: "max",
      //   crop: "faces, edges, entropy",
      //   q: 50,
      // };

      // const { focalPoint } = metaImage;

      // // if focal point is set, overwrite crop settings
      // if (focalPoint) {
      //   ixParams = {
      //     ...ixParams,
      //     fit: "crop",
      //     crop: "focalpoint",
      //     "fp-x": focalPoint[0],
      //     "fp-y": focalPoint[1],
      //   };
      // }

      // metaImageUrl = metaImage
      //   ? client.buildURL(processedUrl, {
      //       ...ixParams,
      //       w: 1200,
      //       h: 1200,
      //     })
      //   : null;
    }

    return (
      <Helmet>
        <title>{metaTitle}</title>
        {/* <!-- Short description of the document (limit to 150 characters) --> */}
        {/* <!-- This content *may* be used as a part of search engine results. --> */}
        <meta name="description" content={metaDescription} />

        {/* <!-- Control the behavior of search engine crawling and indexing --> */}
        {/* <!-- All Search Engines --> */}
        <meta name="robots" content="index,follow" />
        {/* <!-- Google Specific --> */}
        <meta name="googlebot" content="index,follow" />

        {/* facebook og */}
        <meta property="og:title" name="og:title" content={metaTitle} />
        {metaImageUrl && metaImageUrl && metaImageUrl.length > 2 && (
          <meta property="og:image" name="og:image" content={metaImageUrl} />
        )}
        <meta
          property="og:description"
          name="og:description"
          content={metaDescription}
        />
        <meta property="og:type" name="og:type" content="website" />
        <meta
          property="og:url"
          name="og:url"
          content={`${url}${url?.slice(-1) !== "/" ? "" : "/"}`}
        />
        <meta
          property="fb:app_id"
          name="fb:app_id"
          content={process.env.GATSBY_FACEBOOK_APP_ID}
        />

        {/* twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        {metaImageUrl && metaImageUrl && metaImageUrl.length > 2 && (
          <meta name="twitter:image" content={metaImageUrl} />
        )}
      </Helmet>
    );
  }
  return null;
};

export default Meta;
