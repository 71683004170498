import React, { useState, useEffect, useRef } from "react";
import { Field, Button, Text } from "@atoms";
import gsap from "gsap";

const ContactForm = ({ reasons, successState }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = successState || useState(false);
  const [form, setForm] = useState({});
  const errorMessage = useRef();
  const [formReason, setFormReason] = useState();

  useEffect(() => {
    setForm({
      ...form,
      honeypot: "bot-field",
      contactReason: reasons[0],
    });
    setFormReason(reasons[0]);
  }, []);

  useEffect(() => {
    if (error && error.length) {
      gsap.fromTo(
        errorMessage.current,
        { height: 0, scale: 0.9 },
        { height: "auto", duration: 0.2, scale: 1, easing: "Back.easeOut" }
      );
    } else {
      gsap.fromTo(
        errorMessage.current,
        { height: "auto", duration: 0.2 },
        { height: 0 }
      );
    }
  }, [error]);

  const onSubmit = e => {
    e.preventDefault();
    if (form && !success) {
      const formData = new FormData(e.target);
      fetch(`/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => setSuccess(true))
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    } else {
      setError("Please fill out all required fields");
    }
  };

  return (
    <>
      <div ref={errorMessage} className="h-0 overflow-hidden">
        <Text variant="xs" className="inline-block mb-2 py-3 text-red">
          {error}
        </Text>
      </div>
      <form
        name={formReason ? `Contact Form: ${formReason}` : `Contact Form`}
        onSubmit={onSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        method="post"
        className="grid grid-col-2 gap-6"
      >
        <Field
          label="Your Name"
          placeholder="Your name here"
          name="name"
          required
          type="text"
          cols={2}
          onChange={e => {
            setForm({ ...form, name: e.target.value });
          }}
        />
        <Field
          label="Your email address"
          placeholder="Your email address here"
          name="email"
          required
          type="email"
          cols={2}
          onChange={e => {
            setForm({ ...form, email: e.target.value });
          }}
        />
        <Field
          label="Reason For Contact"
          name="contactReason"
          options={reasons}
          required
          type="select"
          cols={2}
          onChange={e => {
            setFormReason(e.target.value);
            setForm({ ...form, contactReason: e.target.value });
          }}
        />
        <Field
          label="Your Message"
          placeholder="Type your message here..."
          name="message"
          textarea
          cols={2}
          onChange={e => {
            setForm({ ...form, message: e.target.value });
          }}
        />
        <Field
          label="Don't fill out if you're human"
          name="bot-field"
          cols={2}
          type="text"
          className="hidden"
        />
        <div className="col-span-full">
          <Button type="submit" color="gold">
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
