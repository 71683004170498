import React from "react";
import { AppLink } from "@base";
import { useAppState } from "@state";

const Button = ({
  color = "white",
  to,
  title,
  type = "button",
  onClick,
  linkObj: LinkObj = AppLink,
  children,
  fullWidth,
  size = "default",
  rounded,
}) => {
  const [{ ec }, dispatch] = useAppState();

  let classes = `inline-flex justify-center items-center cursor-pointer items-center relative z-0 uppercase font-bold group leading-tighter transition duration-200 border-2 rounded-sm`;

  // set size
  switch (size) {
    case "fluid":
      classes += " p-2 text-xs";
      break;
    case "xxs":
      classes += " p-2 text-xs";
      break;
    case "xs":
      classes += " px-5 py-2 text-xxs";
      break;
    case "md":
      classes += " px-5 py-3 text-base";
      break;
    case "lg":
      classes += " px-10 py-5 text-base";
      break;
    case "sm":
      classes += " py-1.5 px-3 text-xs";
      break;
    default:
      classes += " py-2 px-4 text-sm";
      break;
  }

  switch (color) {
    case "white":
      classes +=
        " border-white text-white hover:text-gold hover:bg-white group-hover:text-gold group-hover:bg-white";
      break;
    case "gold":
      classes +=
        " border-gold text-gold hover:text-white hover:bg-gold group-hover:text-white group-hover:bg-gold";
      break;
    case "blue":
      classes +=
        " border-blue text-blue hover:text-white hover:bg-blue group-hover:text-white group-hover:bg-blue";
      break;
    case "pagination":
      classes +=
        " pt-1 border-blue text-gold hover:text-white hover:bg-blue group-hover:text-white group-hover:bg-blue italic font-serif font-normal";
      break;
    default:
      classes += "";
      break;
  }

  if (rounded) {
    classes += " rounded-full";
  }

  if (fullWidth) {
    classes += " w-full";
  }

  if (to && to.length > 1) {
    return (
      <LinkObj to={to} title={title} className={`${classes}`} onClick={onClick}>
        <span className="flex relative z-10 text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={`${classes}`}
      onClick={onClick}
    >
      <span className="flex relative z-10 text-center">{children}</span>
    </button>
  );
};

export default Button;
