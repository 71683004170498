import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query OurValuesQuery {
    craft {
      globalSet(handle: "values") {
        ... on Craft_values_GlobalSet {
          values {
            ... on Craft_values_value_BlockType {
              valueImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              valueTitle
              valueDescription
            }
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { values } = craft.globalSet;

  return {
    values: values?.map(value => {
      return {
        image: resolveImage(value?.valueImage),
        title: value?.valueTitle,
        description: value?.valueDescription,
      };
    }),
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
