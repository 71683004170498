import React from "react";

const Text = ({
  children,
  className: _className,
  variant,
  richText = true,
  quotes = false,
}) => {
  let Tag = "span";
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-sans text-4xl leading-tighter uppercase font-bold tracking-wider";
      break;

    case "h2":
      Tag = "h2";
      classes =
        "font-sans text-3xl leading-tighter uppercase font-bold tracking-wider";
      break;

    case "h3":
      Tag = "h3";
      classes =
        "font-sans text-xl md:text-2.5xl leading-tighter uppercase font-bold  tracking-wider";
      break;

    case "h4":
      Tag = "h4";
      classes =
        "font-sans text-2xl leading-tighter uppercase font-bold tracking-wider";
      break;

    case "h5":
      Tag = "h5";
      classes =
        "font-sans text-xl leading-tighter uppercase font-bold tracking-wider";
      break;

    case "h6":
      Tag = "h6";
      classes =
        "font-sans text-base leading-tighter uppercase font-bold tracking-wider";
      break;

    case "xl":
      classes = "block text-xl leading-normal font-serif";
      break;

    case "lg":
      classes = "block text-lg leading-normal font-serif";
      break;

    case "body":
      classes = "block text-base leading-normal font-serif";
      break;

    case "sm":
      classes = "block text-sm leading-normal font-serif";
      break;

    case "xs":
      classes = "block text-xs font-sans leading-tight font-serif";
      break;

    case "2xs":
      classes = "block text-2xs font-sans leading-tight font-serif";
      break;

    case "label":
      classes =
        "block text-xs font-bold leading-none font-sans uppercase tracking-0.5";
      break;

    default:
      classes = "";
      break;
  }

  return React.Children.toArray(children).map((child, i) => {
    // const components = [RichText, Phone];
    // if children are not components (html or string), then render the html
    if (typeof child.type === "undefined") {
      return (
        <Tag
          key={child.key || i}
          className={`
            ${classes} 
            ${_className} 
            ${!richText ? "" : "rich-text"} 
            ${quotes ? "quotes" : ""} 
            `}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        key={child.key || i}
        className={`
          ${classes} 
          ${_className} 
          ${!richText ? "" : "rich-text"} 
          ${quotes ? "quotes" : ""} 
        `}
      >
        {child}
      </Tag>
    );
  });
};

export default Text;
