import React from "react";
import clsx from "clsx";
import { Container, Text, Button } from "@atoms";
import { productDevDesktop, productDevMobile } from "@images";
import { AppLink } from "@base";
import { useAppState } from "@state";

const ColumnWithHeading = ({
  backgroundImage,
  bgColor = "gray",
  copy,
  efforts,
  elements,
  headline,
  icon,
  id,
  illustration,
  intro,
  left,
  products,
  quality,
  qualitySteps,
  right,
  safety,
  showProductDevImage,
  steps,
  videoUrl,
}) => {
  const [{ modal: _modal }, dispatch] = useAppState();
  const { content: Content, modal, background } = _modal;

  const openModal = url => {
    dispatch({
      type: "openModal",
      content: url ? (
        <div className="max-w-3xl w-full">
          <Text richText={false} className="block fluid-video shadow-xl">
            <iframe
              width="560"
              height="315"
              src={url}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Text>
        </div>
      ) : null,
    });
  };

  return (
    <section
      id={id}
      className={clsx(
        "py-12 md:py-16",
        { "bg-white": bgColor === "white" },
        { "bg-blue-light": bgColor === "gray" },
        { "text-white relative !bg-blue": backgroundImage }
      )}
    >
      {backgroundImage && (
        <div className="absolute w-full h-full z-0 inset-0">
          <div
            className="absolute w-full h-full inset-0 bg-cover bg-no-repeat bg-center opacity-40 z-10"
            style={{ backgroundImage: `url(${backgroundImage?.url})` }}
          />
          <div className="bg-black opacity-30 absolute inset-0 z-20" />
        </div>
      )}

      <Container variant="lg" className="relative z-10">
        {icon && <img src={icon?.url} className="mx-auto w-20 mb-8" />}
        {headline && (
          <Text
            variant="h4"
            className={clsx("text-center text-blue", {
              "!text-white": backgroundImage,
            })}
          >
            {headline}
          </Text>
        )}
        {intro && (
          <Text
            variant="body"
            className="italic text-center mt-4 mb-12 max-w-lg mx-auto"
          >
            {intro}
          </Text>
        )}

        {showProductDevImage && (
          <picture className="block max-w-4xl mx-auto py-8">
            <source srcSet={productDevDesktop} media="(min-width: 800px)" />
            <img src={productDevMobile} className="mx-auto" />
          </picture>
        )}

        {illustration && (
          <img
            src={illustration?.url}
            className="mx-auto max-w-3xl w-full my-8"
          />
        )}

        {videoUrl && (
          <div className="mt-12 mb-16 space-y-4 flex flex-col justify-center items-center">
            <Button size="small" onClick={() => openModal(videoUrl?.english)}>
              Play Video
            </Button>
            <AppLink
              onClick={() => openModal(videoUrl?.spanish)}
              className="font-serif italic text-sm transition duration-200 hover:opacity-70"
            >
              Mira este video en Español
            </AppLink>
          </div>
        )}

        {copy && (
          <Text variant="sm" className="mt-8 max-w-2xl mx-auto list-blue">
            {copy}
          </Text>
        )}

        {left && right && (
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
            {/* Splitting them up into left and right as it's not being handled by the CMS */}
            {!left?.image && !left?.copy && (
              <Text variant="sm" className="list-blue">
                {left}
              </Text>
            )}
            {left?.image && <img src={left?.image?.url} />}
            {left?.copy && (
              <div className="space-y-4">
                <Text variant="sm" className="list-blue">
                  {left?.copy}
                </Text>
                {left?.button && (
                  <Button to={left?.button?.url} color="blue" size="sm">
                    {left?.button?.text}
                  </Button>
                )}
              </div>
            )}
            {!right?.image && !right?.copy && !right?.button && (
              <Text variant="sm" className="list-blue">
                {right}
              </Text>
            )}
            {right?.image && <img src={right?.image?.url} />}
            {right?.copy && (
              <div className="space-y-4">
                <Text variant="sm" className="list-blue">
                  {right?.copy}
                </Text>
                {right?.button && (
                  <Button to={right?.button?.url} color="blue" size="sm">
                    {right?.button?.text}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        {safety && quality && (
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
            <div>
              <div className="space-y-4 clear-both">
                <Text variant="h4" className="text-blue text-2xl">
                  {safety?.title}
                </Text>
                <img
                  src={safety?.icon?.url}
                  className="relative -top-6 ml-4 w-16 h-16 float-right"
                />
                <Text variant="sm">{safety?.description}</Text>
                <Button to="/food-safety" color="gold" size="sm">
                  Read More
                </Button>
              </div>
            </div>
            <div>
              <div className="space-y-4">
                <Text variant="h4" className="text-blue text-2xl">
                  {quality?.title}
                </Text>
                <Text variant="sm">{quality?.description}</Text>
              </div>
            </div>
          </div>
        )}

        {!!steps?.length && (
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-4 gap-y-8 md:gap-y-12">
            {steps?.map((step, i) => {
              return (
                <div
                  className="flex flex-col space-y-4 px-4"
                  key={`step--${i}`}
                >
                  <img src={step?.icon?.url} className="w-32 mx-auto" />
                  <div className="flex items-baseline">
                    <div className="text-white bg-blue w-6 h-6 flex justify-center items-center rounded-full overflow-hidden flex-shrink-0 mr-2">
                      <Text className="font-bold">{i + 1}</Text>
                    </div>
                    <Text
                      variant="sm"
                      className="!text-base md:!text-sm text-black italic mt-1"
                    >
                      {step?.description}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!!products?.length && (
          <div className="mt-8">
            {products?.map((product, i) => {
              return (
                <div className="mb-4" key={`product--${i}`}>
                  <div className="flex flex-row items-center">
                    <img src={product?.icon?.url} className="w-8" />
                    <Text variant="h6" className="text-blue ml-4">
                      {product?.title}
                    </Text>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-8 mt-4">
                    <Text variant="sm" className="product-list">
                      {product?.column1}
                    </Text>
                    <Text variant="sm" className="product-list">
                      {product?.column2}
                    </Text>
                    <Text variant="sm" className="product-list">
                      {product?.column3}
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!!elements?.length && (
          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 max-w-2xl mx-auto">
            {elements?.map((value, i) => {
              return (
                <div
                  className="flex flex-col text-center space-y-4 px-4"
                  key={i}
                >
                  <img src={value?.icon?.url} className="w-32 mx-auto" />
                  <Text variant="h6" className="text-blue">
                    {value?.title}
                  </Text>
                  <Text variant="sm" className="text-black italic">
                    {value?.description}
                  </Text>
                </div>
              );
            })}
          </div>
        )}

        {!!qualitySteps?.length && (
          <div className="flex flex-row flex-wrap mt-8">
            {qualitySteps?.map((step, i) => {
              return (
                <div
                  className="w-full md:w-1/2 lg:w-1/4 flex flex-col space-y-4 px-4 mb-8 relative  sm:odd:before:hidden lg:odd:before:block lg:first:before:!hidden sm:step-arrow"
                  key={`step--${i}`}
                >
                  <img src={step?.icon?.url} className="w-24 mx-auto" />
                  <Text variant="h6" className="text-blue text-center">
                    {step?.title}
                  </Text>
                  <Text variant="sm" className="text-black italic">
                    {step?.description}
                  </Text>
                </div>
              );
            })}
          </div>
        )}

        {!!efforts?.length && (
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-4 gap-y-8 md:gap-y-12">
            {efforts?.map((effort, i) => {
              return (
                <div
                  className="flex flex-col text-center space-y-4 px-4"
                  key={i}
                >
                  <img src={effort?.icon?.url} className="w-24 mx-auto" />
                  <Text variant="sm" className="text-black italic">
                    {effort?.description}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </Container>
    </section>
  );
};

export default ColumnWithHeading;
