import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { loadGTM } from "@utils";
import { Footer } from "@molecules";
import Header from "../organisms/Header";
import Modal from "../organisms/Modal";
import BrowserWarning from "../atoms/BrowserWarning";

const DefaultLayout = ({ children }) => {
  useEffect(() => {
    loadGTM(true);
  }, []);

  // the layout
  return (
    <div id="app_layout" className="min-h-screen bg-blue">
      <Helmet>
        {/* uncomment to load typekit */}
        <link rel="preload" href={process.env.GATSBY_TYPEKIT} as="style" />
        <link rel="stylesheet" href={process.env.GATSBY_TYPEKIT} />
        {/* uncomment to allow social embeds */}
        {/* <script
          async
          defer
          crossOrigin="anonymous"
          src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&autoLogAppEvents=1&version=v7.0&appId=${process.env.GATSBY_FACEBOOK_APP_ID}`}
          nonce="12UgffVI"
        />
        <script
          async=""
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        />
        <script async src="https://www.instagram.com/embed.js" /> */}
      </Helmet>
      <div className="max-w-[1600px] mx-auto flex flex-grow flex-col justify-between overflow-hidden min-h-screen">
        <Header />
        <main className="mt-[4.5em]">{children}</main>
        <Footer />
      </div>
      <BrowserWarning />
      {/* uncomment if embedding facebook posts */}
      {/* <div id="fb-root" /> */}
      <Modal />
    </div>
  );
};

export default DefaultLayout;
