import React, { useRef, useEffect } from "react";
import { Container, Text } from "@atoms";
import { AppLink } from "@base";
import { useAppState } from "@state";
import { useHeaderData } from "@staticQueries";
import { iconLogo, logo } from "@images";
import { MobileNav } from "@molecules";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Header = () => {
  const { left, right } = useHeaderData();
  const [{ layout }, dispatch] = useAppState();
  const { showNav } = layout;

  const dimLogo = useRef();
  const circleShadow = useRef();
  const tl = useRef();
  const st = useRef();

  useEffect(() => {
    // Scrolling header animation
    tl.current = gsap
      .timeline({ paused: true })
      .to(dimLogo.current, {
        autoAlpha: 0,
        ease: "power3.inOut",
        duration: 0.6,
      })
      .to(
        circleShadow.current,
        {
          autoAlpha: 0,
          ease: "power3.inOut",
          duration: 0.6,
        },
        "<"
      );
  }, []);

  useEffect(() => {
    st.current = ScrollTrigger.create({
      trigger: "#app_layout",
      start: "top 0",
      end: "200px",
      onEnter: ({ direction, progress, isActive }) => {
        tl.current.play();
      },
      onEnterBack: ({ direction, progress, isActive }) => {
        tl.current.reverse();
      },
    });
  }, []);

  return (
    <>
      <header className="fixed z-50 w-full max-w-[1600px] bg-white shadow-md min-h-[4.5em]">
        <Container variant="lg">
          <div
            ref={dimLogo}
            className="absolute h-60 w-60 bg-white -top-28 inset-0 mx-auto pt-20 px-14 pb-6 bg-white rounded-full"
          >
            <AppLink
              to="/"
              ariaLabel="Superior Foods"
              className="block w-full h-full mt-6 p-2"
            >
              <div
                style={{ backgroundImage: `url(${logo})` }}
                className="w-full h-full bg-center bg-contain bg-no-repeat"
              />
            </AppLink>
          </div>

          <nav
            aria-label="header-nav"
            className="flex flex-row justify-between -mx-4"
          >
            <ul className="hidden lg:flex flex-row">
              {left?.map((nav, i) => {
                return (
                  <li key={`left-nav--${i}`}>
                    <AppLink
                      to={nav?.url}
                      activeClassName="decoration-2 decoration-gold underline underline-offset-[0.325em]"
                      className="text-gray hover:opacity-70 duration-115 inline-block px-4 py-7"
                    >
                      <Text
                        variant="xs"
                        className="uppercase font-bold relative !font-sans"
                      >
                        {nav?.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>

            <ul className="hidden lg:flex flex-row">
              {right?.map((nav, i) => {
                return (
                  <li key={`right-nav--${i}`}>
                    <AppLink
                      to={nav?.url}
                      activeClassName="decoration-2 decoration-gold underline underline-offset-[0.325em]"
                      className="text-gray hover:opacity-70 duration-115 inline-block px-4 py-7"
                    >
                      <Text
                        variant="xs"
                        className="uppercase font-bold relative !font-sans"
                      >
                        {nav?.text}
                      </Text>
                    </AppLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <AppLink
            to="/"
            ariaLabel="Superior Foods"
            className="absolute w-[3.125rem] h-[3.125rem] top-3.5 inset-0 block mx-auto -mt-px"
          >
            <div
              style={{ backgroundImage: `url(${iconLogo})` }}
              className="w-full h-full bg-center bg-contain bg-no-repeat"
            />
          </AppLink>
          <button
            type="button"
            aria-label="Toggle navigation menu"
            className="block lg:hidden relative w-8 h-10 top-5 toggle-nav text-[2em]"
            onClick={() => {
              dispatch({
                type: !showNav ? "showNav" : "hideNav",
              });
            }}
          />
        </Container>
        <MobileNav />
      </header>
      <div
        ref={circleShadow}
        className="z-10 shadow-md absolute h-60 w-60 bg-white -top-28 inset-0 mx-auto pt-20 px-14 pb-6 bg-white rounded-full"
      />
    </>
  );
};

export default Header;
