import React from "react";
import clsx from "clsx";
import { Icon, Text } from "@atoms";
import { AppLink } from "@base";

const Share = ({ url: _url, hideShare, className: _className }) => {
  const url = encodeURIComponent(_url);
  const buttonClass =
    " text-white flex justify-center items-center w-7 h-7 bg-gold hover:opacity-70 rounded-full transition duration-100 ease-in";

  return (
    <div className={clsx("flex items-center space-x-5 my-10", _className)}>
      {!hideShare && (
        <Text variant="xl" className="font-bold text-blue italic">
          Share:
        </Text>
      )}
      <AppLink
        to={`http://www.linkedin.com/shareArticle?mini=true&url=${url}`}
        className={buttonClass}
      >
        <Icon name="linkedin" className="w-6 h-6" />
      </AppLink>
    </div>
  );
};

export default Share;
