import React from "react";
import { Button, Text } from "@atoms";

const Pagination = ({ total, perPage, model }) => {
  // Set up amount of buttons for pagination
  const paginationButtons = [];
  const buttons = total / perPage;

  for (let button = 0; button < buttons; button++) {
    paginationButtons.push(button);
  }

  const [page, setPage] = model;

  return (
    <>
      {total > perPage && (
        <div className="flex space-x-1 mt-14">
          {paginationButtons.map((button, i) => {
            const neighbors = 1;
            const first = i === 0;
            const last = i === paginationButtons.length - 1;
            const directNeighbor =
              i === page - 1 + neighbors || i === page - 1 - neighbors;
            const distantNeighbor =
              i === page - 1 + (neighbors + 1) ||
              i === page - 1 - (neighbors + 1);
            const current = page === i + 1;

            if (current) {
              return (
                <Button
                  to={`company/careers?page=${i + 1}`}
                  title={`Go to page ${i + 1}`}
                  color="pagination"
                  key={i}
                  onClick={() => {
                    setPage(i + 1);
                  }}
                >
                  <span className="!text-blue group-hover:!text-white">
                    {i + 1}
                  </span>
                </Button>
              );
            } else if (first || directNeighbor || last) {
              return (
                <Button
                  to={`company/careers?page=${i + 1}`}
                  title={`Go to page ${i + 1}`}
                  color="pagination"
                  key={i}
                  onClick={() => {
                    setPage(i + 1);
                  }}
                >
                  {i + 1}
                </Button>
              );
            } else if (distantNeighbor) {
              return <Text className="!mx-4">&hellip;</Text>;
            } else {
              return null;
            }
          })}
        </div>
      )}
    </>
  );
};

export default Pagination;
