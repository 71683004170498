import React from "react";
import clsx from "clsx";
import { Video, Text } from "@atoms";

const CbVideo = ({ videoUrl, caption }) => {
  return (
    <div className={clsx("flex my-6 mb-12", {})}>
      <div className={clsx("w-full")}>
        {videoUrl && (
          <div className="shadow-xl">
            <Video url={videoUrl} />
          </div>
        )}
        <div className="pt-3">
          <Text variant="base" className="text-sm text-blue">
            {caption}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CbVideo;
