import { useStaticQuery, graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "seo") {
        ... on Craft_seo_GlobalSet {
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const previewQuery = ``;

const dataResolver = ({ craft }) => {
  const { metaTitle, metaImage, metaDescription } = craft.globalSet;

  return {
    meta: {
      siteTitle: metaTitle,
      metaImage: resolveImage(metaImage),
      metaDescription,
    },
  };
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergeCraftPreview(response, previewQuery);
  return dataResolver(data);
};

export default useData;
