/* eslint-disable global-require */
// Ideas: common missing sizes, plugins, additional variants

module.exports = {
  mode: "jit",
  content: ["./src/**/*.{js,svg,css}"],
  theme: {
    fontSize: {
      "2xs": ".625rem",
      xs: ".75rem",
      sm: ".88rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "1.5xl": "1.33rem",
      "2xl": "1.5rem",
      "2.5xl": "1.75rem",
      "3xl": "2rem",
      "4xl": "2.5rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
      "8xl": "6rem",
      "9xl": "7rem",
      "10xl": "8rem",
      h1: "5rem",
      h2: "4rem",
      h3: "3rem",
      h4: "2rem",
      h5: "1.75rem",
      inherit: "inherit",
    },
    screens: {
      xxs: { max: "350px" }, // for super small screens
      sm: "700px", // bigger than most phones
      md: "600px",
      lg: "1000px",
      xl: "1400px", // larger than 15" macbook pro
      xxl: "2000px",
    },
    fontFamily: {
      sans: ["brandon-grotesque", "sans-serif"],
      serif: ["ff-tisa-web-pro", "serif"],
    },
    extend: {
      colors: {
        blue: {
          DEFAULT: "#45668b",
          dark: "#0a1f37",
          light: "#eceff3",
          off: "#a1bad7",
          "light-text": "#bac3d0",
        },
        black: {
          DEFAULT: "#414141",
        },
        gray: {
          DEFAULT: "#717171",
        },
        gold: {
          DEFAULT: "#d7ae4b",
          border: "#e0c175",
        },
        white: {
          DEFAULT: "#fff",
        },
        red: {
          DEFAULT: "red",
        },
      },
      maxWidth: {
        xs: "20rem",
        sm: "24rem",
        md: "28rem",
        lg: "32rem",
        xl: "36rem",
        "2xl": "44rem",
        "3xl": "52rem",
        "4xl": "60rem",
        "5xl": "68rem",
        "6xl": "76rem",
        "7xl": "84rem",
        "8xl": "92rem",
        full: "100%",
      },
      transitionProperty: {
        default:
          " background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, box-shadow",
      },
      opacity: {
        10: ".1",
        15: ".15",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".9",
        90: ".9",
      },
      letterSpacing: {
        // calculated to px value of # @ 16px
        "-3": "-0.1875rem",
        "-2": "-0.125rem",
        "-1": "-0.0625rem",
        0: "0px",
        0.5: "0.03125rem",
        1: "0.0625rem",
        2: "0.125rem",
        3: "0.1875rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
        "7/8-screen": "87.5vh",
      },
      height: {
        25: "25%",
        "1/2-screen": "50vh",
      },
      width: {
        "1/1": "100%",
        "1/7": "14%",
        "2/7": "28%",
        "3/7": "42%",
        "4/7": "58%",
        "5/7": "72%",
        "6/7": "86%",
      },
      transitionDuration: {
        115: ".15s",
      },
      fill: theme => theme("colors"),
      stroke: theme => theme("colors"),
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive"],
    fill: ["hover", "group-hover", "responsive"],
    scale: ["hover", "group-hover", "responsive"],
    translate: ["hover", "group-hover", "responsive"],
    boxShadow: ["hover", "group-hover", "responsive"],
    animation: ["hover", "group-hover", "responsive"],
    textColor: ["responsive", "hover", "focus", "active", "group-hover"],
  },
};
