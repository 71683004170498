import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

const InViewWrapper = ({
  children,
  className,
  inViewClassName,
  onEnter = () => {},
  threshold = 0.5,
  rootMargin = "0px",
}) => {
  const ref = useRef();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
          // eslint-disable-next-line no-unused-expressions
          onEnter;
        }
      },
      { threshold, rootMargin }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className={clsx(className, {
        [inViewClassName]: visible,
      })}
    >
      {children}
    </div>
  );
};

export default InViewWrapper;
