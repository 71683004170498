import React from "react";
import clsx from "clsx";
import { Text } from "@atoms";

const CbCopy = ({ copy, centered }) => {
  return (
    <Text
      variant="body"
      className={clsx("rich-text list-blue blog", {
        "text-center mx-auto": centered,
      })}
    >
      {copy}
    </Text>
  );
};

export default CbCopy;
